import React from "react";
import {
    NavLink,
  } from "react-router-dom";

const ContactThankyou = () => {
    return (
      <section className="py-52">
        <div>
          <div>
            <p className="text-xl font-bold text-gray-800 text-center">
                Thank you!
            </p>
            <p className="text-center text-gray-600">
              Your message has been sent and I will get back to you shortly
            </p>
          </div>
          <div className="flex flex-col items-center py-4">
            <NavLink
                to='/contact'
                activeClassName="text-blue-400"
                className="inline-block mb-3 rounded bg-blue-300 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-blue-400"
            >
                Send another message
            </NavLink>
          </div>
        </div>
      </section>
    );
  };
  
  export default ContactThankyou;
  