import React, { Component } from 'react';
import Resume from '../../../assets/Dylan_Zeigler_Resume.pdf';

class DownloadResume extends Component {

  render() {

    return (
        <div className = "App">
          <a
          href={Resume}
          rel="noreferrer"
          target = "_blank"
          className="inline-block mb-3 rounded bg-blue-600 text-center border-0 py-2 px-6 text-white leading-7 tracking-wide hover:bg-blue-800"
          download="Dylan-Zeigler-Resume.pdf"
          >
          Download Resume
          </a>
        </div>
    );
  }
}

export default DownloadResume;