import React from "react";

const ProficiencyItem = (props) => {
  const { title, percentage } = props.skill;
  return (
      <div className="m-1">
        <div className="flex justify-between items-center mb-1">
            <div class="rounded-full text-s text-white bg-blue-600 py-1 px-2" 
              style={{ opacity: `${percentage}` }}>{title}</div>
        </div>
      </div>
  );
};

export default ProficiencyItem;
