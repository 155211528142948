import React from "react";
import aidchat from "../../../images/portfolio/aidchat.png";
import aipe from "../../../images/portfolio/aipe.png";
import arise from "../../../images/portfolio/arise.png";
import cmm from "../../../images/portfolio/cmm.jpg";
import orion from "../../../images/portfolio/orion.jpg";
import ttauri from "../../../images/portfolio/ttauri.jpg";
import vpa from "../../../images/portfolio/vpa.png";
import PortfolioItem from "./PortfolioItem";

const portfolioData = [
  {
    id: 1,
    image: aidchat,
    title: "AidChat",
    description:
      "Tech Lead who built a production level chatbot app and energized team to meet product vision.",
  },
  {
    id: 1,
    image: aipe,
    title: "AI for Product Engineering",
    description:
      "Tech Lead who redefined how manufactured parts are designed and produced with AI enabled capabilities.",
  },
  {
    id: 2,
    image: cmm,
    title: "Cognitive Mission Manager",
    link: "https://www.denverpost.com/2022/05/03/wildfires-west-lockheed-aerospace-artificial-intelligence/",
    description:
      "Data Engineer who provided multiple sources of data for AI driven wildfire management tools.",
  },
  {
    id: 3,
    image: arise,
    title: "ARISE",
    link: "https://trino.io/",
    description:
      "Data Engineer/Scrum Master who pipelined and stored large scale time-series data for simulations.",
  },
  {
    id: 4,
    image: vpa,
    title: "Virtual Program Assistant",
    description:
      "Backend Developer who stored data and deployed an API for a modern program management dashboard.",
  },
  {
    id: 5,
    image: orion,
    title: "Orion",
    link: "https://www.nasa.gov/exploration/systems/orion/index.html",
    description:
      "Flight Software Developer who built drivers for the vision processing unit on the Orion spacecraft destined for Mars.",
  },
  {
    id: 6,
    image: ttauri,
    title: "T-TAURI",
    link: "https://news.lockheedmartin.com/2021-03-01-Lockheed-Martin-and-NEC-Put-AI-to-Work-on-Programs-like-NASAs-Artemis-Mission",
    description:
      "Full Stack Developer/Scrum Master who maintained time series data system for satellite anomaly detection.",
  },
];

const Portfolio = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {portfolioData.map((portfolio, id) => (
          <PortfolioItem portfolio={portfolio} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
