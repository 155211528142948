import React from "react";
import Gallery  from "react-photo-gallery";

const photos = [
  {
    src: 'https://i.imgur.com/Han7KiN.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/U5jUZeN.jpg',
    width: 628,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/GtH0dvB.jpg',
    width: 969,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/mFUcksU.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/DyXz3Rb.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/NnldztY.jpg',
    width: 265,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/cFm6ZOS.jpg',
    width: 628,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/YmbI66h.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/65HxSjq.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/mqkei7D.jpg',
    width: 628,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/VsoFsrq.jpg',
    width: 628,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/60j2d78.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/INO8rQk.jpg',
    width: 264,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/0GJZxNv.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/a4PcM36.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/tJB7a61.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/boampDq.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/rjZBpqf.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/8tBw5yw.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/3rkRe8T.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/x8YiOnx.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/vutgFss.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/61QUzdW.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/xHo3EdC.jpg',
    width: 265,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/yLU3XPA.jpg',
    width: 265,
    height: 471,
  },

  {
    src: 'https://i.imgur.com/aXY4aqP.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/0Q2kN5U.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/KdiLsdH.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/Hv0OM8e.jpg',
    width: 1351,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/bAZcU5V.jpg',
    width: 265,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/FHxcfml.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/jhnKG0a.jpg',
    width: 837,
    height: 471,
  },
  {
    src: 'https://i.imgur.com/HINbUqx.jpg',
    width: 264,
    height: 471,
  },


];

const Photos = () => {
  return (
    <section className="pb-10">
      <Gallery photos={photos} />
    </section>
  );
};

export default Photos;
