import React from "react";
import Proficiencies from "./Proficiencies/Proficiencies";

const About = () => {
  return (
    <section className="py-8">
      <div className="flex flex-wrap md:px-4">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className="text-2xl text-gray-800 font-bold mb-4">I’m Dylan, a software developer based in Denver.</h3>
            <p className="text-sm text-gray-500 leading-6 mb-3">
              I am an ambitious individual who is detail oriented, a strategic thinker, 
              and passionate about leading a software team to contribute to the top level 
              goals of the organization.
            </p>
            <p className="text-sm text-gray-500 leading-6 mb-3">
              One of my biggest passions is upskilling those around me. People who have 
              inspired me the most have been my coworkers through daily standups, late 
              night integrations, and weekend excursions. I believe meaningful interactions 
              and an inclusive environment contributes to a healthy team culture that can 
              achieve anything.
            </p>
            <p className="text-sm text-gray-500 leading-6 mb-3">
              Outside of work, you can find me either outdoors enjoying the mountains or 
              indoors working on home-improvement projects. In the summers I like to go 
              on hikes, hangout with friends at a park, or take road trips with my fiance. 
              In the winter I enjoy snowboarding, playing board games, or reading a good 
              book. I also like to volunteer through tutoring or campsite cleanups, and 
              I love to organize trips with friends to explore new places. If you ever meet 
              me in person, ask me about my trip to Japan!
            </p>
          </div>
          <Proficiencies />
        </div>
      </div>
    </section>
  );
};

export default About;
