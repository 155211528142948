import React from "react";
import ProficiencyItem from "./ProficiencyItem";

const skillData = [
  {
    title: "Python",
    percentage: 1.0,
  },
  {
    title: "AWS",
    percentage: 1.0,
  },
  {
    title: "Git",
    percentage: 1.0,
  },
  {
    title: "CICD",
    percentage: 0.95,
  },
  {
    title: "PostgreSQL",
    percentage: 0.95,
  },
  {
    title: "Pandas",
    percentage: 0.95,
  },
  {
    title: "Backend",
    percentage: 0.95,
  },
  {
    title: "ETL",
    percentage: 0.9,
  },
  {
    title: "Docker",
    percentage: 0.9,
  },
  {
    title: "Infrastructure",
    percentage: 0.9,
  },
  {
    title: "Kubernetes",
    percentage: 0.9,
  },
  {
    title: "LangChain",
    percentage: 0.9,
  },
  {
    title: "Kubeflow",
    percentage: 0.9,
  },
  {
    title: "C/C++",
    percentage: 0.85,
  },
  {
    title: "SQL",
    percentage: 0.85,
  },
  {
    title: "KubeCTL",
    percentage: 0.85,
  },
  {
    title: "NLP",
    percentage: 0.8,
  },
  {
    title: "Deep Learning",
    percentage: 0.8,
  },
  {
    title: "ReactJS",
    percentage: 0.8,
  },
  {
    title: "HayStack",
    percentage: 0.8,
  },
  {
    title: "Geospatial",
    percentage: 0.8,
  },
  {
    title: "Linux",
    percentage: 0.8,
  },
  {
    title: "Presto",
    percentage: 0.75,
  },
  {
    title: "Kafka",
    percentage: 0.75,
  },
];

const Proficiencies = () => {
  return (
    <div className="py-4">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="md:mx-4">
            <h3 className="text-2xl text-gray-800 font-bold mb-4">Proficiencies</h3>
          </div>
        </div>
        {skillData.map((skill) => (
          <ProficiencyItem skill={skill} />
        ))}
      </div>
    </div>
  );
};

export default Proficiencies;
