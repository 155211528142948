import React from "react";
import { FaPeopleCarry } from "react-icons/fa";
import { BsStack, BsCloudArrowUp } from "react-icons/bs";
import { AiOutlinePartition } from "react-icons/ai";
import { GiTeePipe, GiBrain } from "react-icons/gi"
import { ImLoop2 } from "react-icons/im"
import { MdComputer } from "react-icons/md"
import SkillItem from "./SkillItem";

const serviceData = [
  {
    id: 1,
    icon: <BsStack />,
    title: "Full Stack Development",
    description:
      `I like to often step outside my comfort zone to fullfill necesary roles on any project 
      I am apart of. From database management to frontend deployments, I have experience designing, 
      building, and deploying all levels of the tech stack in a production environment.`,
  },
  {
    id: 2,
    icon: <GiTeePipe />,
    title: "Data Pipelining",
    description:
      `Whether it's a few gigs or several petabytes, data is usually the first critical step in 
      any project's objectives. In both tabular and time series data formats I have experience 
      developing and managing pipelines to efficiently bring data to my team members.`,
  },
  {
    id: 3,
    icon: <FaPeopleCarry />,
    title: "Leadership",
    description:
      `Graduated from Lockheed Martin's Engineering Leadership Development Program which is a 
      3 year track on upskiling leadership talent. Either official or unofficial, I have served 
      in leadership roles in any position I am apart of.`,
  },
  {
    id: 4,
    icon: <ImLoop2 />,
    title: "Agile Advocate",
    description:
      `I always promote agile concepts when it makes sense for the team. I like to follow the 
      mantra of follow the rules, build good habits, break the rules (from Spotifys Henrik Kniberg).
      Agile provides structure to a team but should fit to what the team needs.`,
  },
  {
    id: 5,
    icon: <MdComputer />,
    title: "Human Computer Interaction",
    description:
      `In any product I design, I always remember the user. A proper interface that is clean, simple, 
      and easy to use will always provide value. I push for the idea of an invisible interface where 
      interactions should be effortless and require little/no training.`,
  },
  {
    id: 6,
    icon: <AiOutlinePartition />,
    title: "System Design",
    description:
      `Working in an enterprise role I am often faced with many complex systems that need integration.
      This has built up a systems level of thinking I exercise day to day. In a virtual working
      environment, I have found success through showing system visuals in apps like Mural.`,
  },
  {
    id: 7,
    icon: <GiBrain />,
    title: "Machine Learning",
    description:
      `Working on a Corporate AI Consulting team and doing a specialization in Machine learning
      for graduate school, I have plenty of exposure to AI/ML concepts. Personally, I have trained
      models in time series, deep learning, and classification tasks.`,
  },
  {
    id: 8,
    icon: <BsCloudArrowUp />,
    title: "Cloud Deployments",
    description:
      `Deployed applications to cloud environments including Red Hat OpenShift, Tanzu Kubernetes 
      Grid, AWS and more. Utilized automated deployment techniques within Gitlab CI/CD runners. 
      Scaled apps with tools suchs as nginx, traefik, and gunicorn.`,
  },
];

const Skill = () => {
  return (
    <section className="pb-10">
      <div className="flex flex-wrap md:px-4">
        {serviceData.map((service, id) => (
          <SkillItem service={service} key={id} />
        ))}
      </div>
    </section>
  );
};

export default Skill;
