import React from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Switch,
} from "react-router-dom";
import About from "../../pages/About/About";
import Photos from "../../pages/Photos/Photos";
import Contact from "../../pages/Contact/Contact";
import ContactThankyou from "../../pages/Contact/ContactThankyou";
import Portfolio from "../../pages/Portfolio/Portfolio";
import Skill from "../../pages/Skill/Skill";

const navbarData = [
  {
    id: 1,
    title: "About",
    to: "/about",
  },
  {
    id: 2,
    title: "Skills",
    to: "/skills",
  },
  {
    id: 5,
    title: "Experience",
    to: "/Experience",
  },
  {
    id: 6,
    title: "Photos",
    to: "/photos",
  },
  {
    id: 7,
    title: "Contact",
    to: "/contact",
  },
];

const Navbar = () => {
  return (
    <Router>
      <nav className="md:mx-8 mb-3 px-6 py-2 z-10 sticky top-0 bg-white shadow rounded">
        <ul className="flex flex-wrap">
          {navbarData.map((el, id) => (
            <LinkItem el={el} key={id} />
          ))}
        </ul>
      </nav>

      <Switch>
        <Route exact path="/">
          <About />
        </Route>
        <Route path="/skills">
          <Skill />
        </Route>
        <Route path="/Experience">
          <Portfolio />
        </Route>
        <Route path="/photos">
          <Photos />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/thankyou">
          <ContactThankyou />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
    </Router>
  );
};

export default Navbar;

const LinkItem = (props) => {
  const { title, to } = props.el;
  return (
    <li className="m-3 lg:mx-5">
      <NavLink
        to={to}
        activeClassName="text-blue-600"
        className="text-gray-800 text-medium hover:text-blue-600"
      >
        {title}
      </NavLink>
    </li>
  );
};
